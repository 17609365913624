/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * museo-sans-rounded:
 *   - http://typekit.com/eulas/000000000000000077359942
 *   - http://typekit.com/eulas/00000000000000007735994a
 *   - http://typekit.com/eulas/000000000000000077359977
 *   - http://typekit.com/eulas/000000000000000077359984
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-04-14 03:25:53 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=osr6gyr&ht=tk&f=9938.9939.39002.39004&a=10172174&app=typekit&e=css");

@font-face {
font-family:"museo-sans-rounded";
src:url("https://use.typekit.net/af/00279f/000000000000000077359942/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/00279f/000000000000000077359942/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/00279f/000000000000000077359942/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;
}

@font-face {
font-family:"museo-sans-rounded";
src:url("https://use.typekit.net/af/d39206/00000000000000007735994a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/d39206/00000000000000007735994a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/d39206/00000000000000007735994a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"museo-sans-rounded";
src:url("https://use.typekit.net/af/f8a7d1/000000000000000077359977/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/f8a7d1/000000000000000077359977/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/f8a7d1/000000000000000077359977/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:300;
}

@font-face {
font-family:"museo-sans-rounded";
src:url("https://use.typekit.net/af/a52fd8/000000000000000077359984/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/a52fd8/000000000000000077359984/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/a52fd8/000000000000000077359984/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

.tk-museo-sans-rounded { font-family: "museo-sans-rounded",sans-serif; }
